import cover from "../assets/img/login-cover.png";
import { LoginForm } from "./LoginForm";
import arkan_logo from "../assets/img/arkan-logo.png";
import dalfin_logo from "../assets/img/dalfin-white.png";

export const Login = () => {
  return (
    <>
      <div className="lg:grid grid-cols-2 h-screen max-lg:flex align-middle justify-center">
        <div className="max-lg:hidden relative overflow-hidden flex align-middle">
          <img src={cover} alt="login-cover" className=" object-cover w-screen" />
        </div>
        <div className="flex justify-center align-middle">
          <div className="absolute flex space-x-4 right-4 top-4">
            <img src={arkan_logo} alt="" className="w-24 " />
            <img src={dalfin_logo} alt="" className="w-24 " />
          </div>
          <LoginForm />
        </div>
      </div>
    </>
  );
};
