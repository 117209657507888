import { Link } from "react-router-dom";
import arkan_img from "../assets/img/arkan-logo.png";
import footer_img from "../assets/img/footer-img.png";

const Footer = () => {
  return (
    <div className="relative bg-[#495a85] lg:px-20 px-12 pt-12">
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-6 text-white">
        <div className="md:col-span-2 md:max-lg:flex gap-4">
          <img className="bg-white rounded-xl p-4 w-52 h-min self-center" src={arkan_img} alt="" />
          <div>
            <h5 className="my-2">PT ARKAN JAYA NUSANTARA</h5>
            <a href="https://maps.app.goo.gl/Jfw1vjUiXHMZhBae8" target="_blank" rel="noreferrer noopener" className="font-roboto text-[15px] font-light text-white">
              Ruby Commercial - Summarecon Bekasi Blok TB No. 11 Jl. Boulevard Selatan,
              <br />
              Marga MulyaKota Bekasi, Jawa Barat 17142, Indonesia
            </a>
          </div>
        </div>
        <div>
          <h5 className="pt-5">Follow and contact us:</h5>
          <ul>
            <li className="my-4">
              <a href="mailto:info@ajn-indonesia.co.id" target="_blank" rel="noreferrer noopener" className="flex gap-2 font-roboto text-sm font-normal hover:underline undelinde-offset-1 w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                  <path d="M3 7l9 6l9 -6" />
                </svg>
                info@ajn-indonesia.co.id
              </a>
            </li>
            <li className="my-4">
              <a href="https://www.instagram.com/arkanjayanusantara/" target="_blank" rel="noreferrer noopener" className="flex gap-2 font-roboto text-sm font-normal hover:underline undelinde-offset-1 w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50" style={{ fill: `#FFFFFF` }}>
                  <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path>
                </svg>
                arkanjayanusantara
              </a>
            </li>
            <li className="my-4">
              <a href="https://www.facebook.com/people/Arkan-Jaya-Nusantara/100063888170592/" target="_blank" rel="noreferrer noopener" className="flex gap-2 font-roboto text-sm font-normal hover:underline undelinde-offset-1 w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24" style={{ fill: `#FFFFFF` }}>
                  <path d="M11.666,2.005C6.62,2.17,2.374,6.251,2.025,11.288c-0.369,5.329,3.442,9.832,8.481,10.589V14.65H8.892 c-0.726,0-1.314-0.588-1.314-1.314v0c0-0.726,0.588-1.314,1.314-1.314h1.613v-1.749c0-2.896,1.411-4.167,3.818-4.167 c0.357,0,0.662,0.008,0.921,0.021c0.636,0.031,1.129,0.561,1.129,1.198v0c0,0.663-0.537,1.2-1.2,1.2h-0.442 c-1.022,0-1.379,0.969-1.379,2.061v1.437h1.87c0.591,0,1.043,0.527,0.953,1.111l-0.108,0.701c-0.073,0.47-0.477,0.817-0.953,0.817 h-1.762v7.247C18.235,21.236,22,17.062,22,12C22,6.366,17.341,1.821,11.666,2.005z"></path>
                </svg>
                Arkan Jaya Nusantara
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h5 className="pt-5">Web terkait:</h5>
          <ul>
            <li className="my-4">
              <a href="https://ajn-indonesia.co.id/" target="_blank" rel="noreferrer noopener" className="flex gap-2 font-roboto text-sm font-normal hover:underline undelinde-offset-1 w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                </svg>
                AJN Indonesia
              </a>
            </li>
            <li className="my-4">
              <a href="https://www.dalfincare.com/" target="_blank" rel="noreferrer noopener" className="flex gap-2 font-roboto text-sm font-normal hover:underline undelinde-offset-1 w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                </svg>
                Dalfin
              </a>
            </li>
            <li className="my-4">
              <a href="https://e-katalog.lkpp.go.id/?lang=en" target="_blank" rel="noreferrer noopener" className="flex gap-2 font-roboto text-sm font-normal hover:underline undelinde-offset-1 w-fit">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle" width="18" height="18" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                </svg>
                e-Katalog
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-16 text-white font-roboto text-sm font-normal text-center py-5">Copyright © 2022 PT Arkan Jaya Nusantara All rights reserved.</div>
      <div className="absolute bottom-1 right-2 w-44 max-md:hidden">
        <img src={footer_img} className="" alt="" />
      </div>
    </div>
  );
};

export default Footer;
