import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProfileBiodata from "./ProfileBiodata";
import ProfileManagement from "./ProfileManagement";
import ProfileUserAlat from "./ProfileUserAlat";
import ProfileIPSRS from "./ProfileIPSRS";

const Profile = () => {
  const [activeComponent, setActiveComponent] = useState("A");

  const renderNestedComponent = () => {
    if (activeComponent === "A") {
      return <ProfileBiodata />;
    } else if (activeComponent === "B") {
      return <ProfileManagement />;
    } else if (activeComponent === "C") {
      return <ProfileUserAlat />;
    } else if (activeComponent === "D") {
      return <ProfileIPSRS />;
    }
    // Tambahkan kondisi lain jika diperlukan
  };
  return (
    <>
      <div className="bg-light md:px-20 px-10 py-8 ">
        <div className="font-roboto text-sm font-normal text-black">
          <Link className="text-[#8B887E]">Home /</Link> Profil
        </div>
        <div className="bg-white  border-b border-[#F0F0F0] rounded-lg lg:p-14 p-8">
          <div className="grid lg:grid-cols-4 ">
            <button className={`font-monserrat font-normal text-[15px]  text-left py-4  ${activeComponent === "A" ? "border-b border-dark w-fit text-dark" : "text-black"}`} onClick={() => setActiveComponent("A")}>
              Biodata
            </button>
            <button className={`font-monserrat font-normal text-[15px]  text-left py-4  ${activeComponent === "B" ? "border-b border-dark w-fit text-dark" : "text-black"}`} onClick={() => setActiveComponent("B")}>
              Manajemen
            </button>
            <button className={`font-monserrat font-normal text-[15px]  text-left py-4  ${activeComponent === "C" ? "border-b border-dark w-fit text-dark" : "text-black"}`} onClick={() => setActiveComponent("C")}>
              Penggunaan Alat
            </button>
            <button className={`font-monserrat font-normal text-[15px]  text-left py-4  ${activeComponent === "D" ? "border-b border-dark w-fit text-dark" : "text-black"}`} onClick={() => setActiveComponent("D")}>
              Teknisi Rumah Sakit (IPSRS)
            </button>
          </div>
          <div className="pt-12">{renderNestedComponent()}</div>
        </div>
      </div>
    </>
  );
};

export default Profile;
