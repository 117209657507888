import { useState } from "react";
import img from "../assets/img/img.png";
import { React } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

const Detail = ({ data }) => {
  const [linkPopup, setLinkPopup] = useState("");
  const [active, setActive] = useState(false);
  const toggleActive = () => {
    setActive(!active);
  };
  const handleLinkPopup = (link) => {
    setLinkPopup(link);
    toggleActive();
  };

  return (
    <>
      <div className="bg-white shadow-md md:px-12 md:pt-16 pt-8 ">
        <div className="lg:grid grid-cols-4 pb-10">
          <div className="col-span-1 max-lg:px-12 max-sm:px-8 gap-6 overflow-hidden">
            <img src={data?.thumbnail !== null ? data?.thumbnail : img} className=" w-full rounded-xl aspect-square object-cover cursor-pointer" alt="" onClick={() => handleLinkPopup(data?.thumbnail)} />
            <Swiper slidesPerView={4} spaceBetween={20} navigation={true} modules={[Navigation]} className="mySwiper my-3">
              {data?.images.map((item, index) => {
                return (
                  <SwiperSlide key={index} onClick={() => handleLinkPopup(item.image)} className="">
                    <img src={item.image !== null ? item.image : img} className="rounded aspect-square cursor-pointer object-cover" alt="" />
                  </SwiperSlide>
                );
              })}
              {/* <SwiperSlide className="">
                <img src={img} className="rounded aspect-square cursor-pointer object-cover" alt="" />
              </SwiperSlide>
              <SwiperSlide className="">
                <img src={img} className="rounded aspect-square cursor-pointer object-cover" alt="" />
              </SwiperSlide>
              <SwiperSlide className="">
                <img src={img} className="rounded aspect-square cursor-pointer object-cover" alt="" />
              </SwiperSlide>
              <SwiperSlide className="">
                <img src={img} className="rounded aspect-square cursor-pointer object-cover" alt="" />
              </SwiperSlide> */}
            </Swiper>
          </div>
          <div className="col-span-3 px-12 max-sm:px-8 py-5 break-words">
            <p className="xl:text-4xl lg:text-3xl text-2xl xl:leading-[3.5rem] md:leading-[2rem]">{data?.name}</p>
            <div className="xl:text-3xl lg:text-2xl text-xl my-4">
              <p className="text-dark">Type: {data?.type}</p>
              <p className="text-dark">Price : {data?.price}</p>
            </div>
            <h3 className="lg:text-2xl text-lg">Stok : {data?.stock}</h3>
            <p className="text-lg"></p>
            <p className="text-lg">Detail:</p>
            <ul>
              <li>
                <p className="text-lg">· Kategori : {data?.category?.name}</p>
              </li>
              <li>
                <p className="text-lg">· Brand: {data?.brand?.name}</p>
              </li>
              <li>
                <p className="text-lg">· Unit: {data?.unit?.name}</p>
              </li>
              <li>{/* <p className="text-lg">· modality: {data?.modality.name}</p> */}</li>
              <li>
                <p className="text-lg">· Plat Number : {data?.plat_number}</p>
              </li>
            </ul>
            <p className="text-lg">{data?.description}</p>
          </div>
        </div>
        <div className="flex flex-row-reverse pb-12 px-8 max-sm:block">
          <a
            href="https://service.ajn-indonesia.co.id/"
            className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium max-sm:w-full w-32 py-1 h-10 max-sm:my-2 sm:ml-5 flex justify-center items-center hover:underline underline-offset-1"
          >
            Beli
          </a>
          <a
            href="https://service.ajn-indonesia.co.id/"
            className="bg-white border-[1px] border-dark rounded-md text-dark font-monserrat text-[15px] font-medium max-sm:w-full w-32 py-1 h-10 max-sm:my-2 sm:ml-5 flex justify-center items-center hover:underline underline-offset-1"
          >
            + Keranjang
          </a>
        </div>
      </div>
      <div className={`${active ? "" : "hidden"}`}>
        <div className="bg-black/50 fixed z-20 w-full h-full top-0 left-0 right-0" onClick={toggleActive} />
        <div className="fixed flex justify-center h-screen z-40 overflow-hidden top-0 left-0 w-screen">
          <div className="bg-white space-y-5 p-5 w-80 rounded self-center">
            <div className="flex justify-end">
              <button className=" text-[#0F75BC] hover:bg-gray-200 rounded" onClick={toggleActive}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#576FAA" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className=" text-dark border-b border-dark"></div>
            <img src={linkPopup} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
