import { Link, useNavigate } from "react-router-dom";
import { getDataProduct } from "../api";
import Produk from "./Produk";
// import dummyData from "../assets/data/dataKeranjang";
import { useEffect, useState } from "react";

// const carts = [
//   {
//     product: dummyData[0],
//     quantity: 1,
//   },
// ];

export const Keranjang = () => {
  // const [selectedData, setSelectedData] = useState(dummyData.map((product) => ({ ...product, quantity: 1, isSelected: false })));
  const [selectedData, setSelectedData] = useState([]);
  const navigate = useNavigate();

  function getData(id) {
    const data = selectedData.filter((product) => product.id === id);

    return data[0] || null;
  }

  function handleSelected(id) {
    return (selected) => {
      console.log(selected);

      return setSelectedData([
        ...selectedData.map((item) => {
          if (item.id === id)
            return {
              ...item,
              isSelected: selected,
            };

          return item;
        }),
      ]);
    };
  }

  function handleCheckout() {
    const data = selectedData.filter((item) => item.isSelected);
    navigate("/checkout", { state: { data } });
  }

  console.log(selectedData);

  function handleChange(id) {
    return (quantity) => {
      const data = getData(id);

      if (!data) return;

      const newData = { ...data, quantity };

      setSelectedData(selectedData.map((item) => (item.id === id ? newData : item)));
    };
  }

  const total = selectedData.filter((item) => item.isSelected).reduce((acc, item) => acc + item.quantity, 0);

  useEffect(() => {
    getDataProduct().then((res) => {
      setSelectedData(res.data.data);
      console.log(res.data.data);
    });
  });

  return (
    <>
      <div className="bg-light md:px-20 px-10 py-8 ">
        <div className="font-roboto text-sm font-normal text-black">
          <Link className="text-[#8B887E]">Home /</Link> Keranjang
        </div>
        <div className="font-roboto font-medium text-black text-xl leading-10 py-5">Keranjang</div>
        <div className="flex lg:flex-row flex-col-reverse lg:space-x-4">
          <div className="lg:basis-2/3">
            {selectedData.map((product) => (
              <Produk key={product.id} product={product} onChange={handleChange(product.id)} onSelected={handleSelected(product.id)} />
            ))}
          </div>
          <div className="lg:basis-1/3 max-lg:mb-6">
            <div className="bg-white p-6 rounded-md">
              <div className="font-roboto font-normal text-sm text-[706D65] py-3">Ringkasan belanja</div>
              <div className="font-monserrat font-normal text-xs text-dark pb-5 mb-5 border-b border-[#D9D9D9]">Jumlah barang ({total})</div>
              {selectedData
                .filter((item) => item.isSelected)
                .map((item) => (
                  <div className="font-roboto font-normal text-xs text-[706D65]" key={item.id}>
                    {item.nama} x {item.quantity}
                  </div>
                ))}
              <div className="bg-dark rounded-md text-center w-full h-9">
                <button onClick={handleCheckout} className="text-white font-monserrat text-xs font-medium leading-10 hover:underline underline-offset-1">
                  BELI
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
