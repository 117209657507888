import { React } from "react";
import img from "../assets/img/img.png";

const Produk = ({ product, onSelected, onChange }) => {
  return (
    <>
      <div className="relative bg-white border-b border-[#F0F0F0] overflow-hidden p-5">
        <div className="flex items-center my-5">
          <div>
            <input
              className="text-dark"
              type="checkbox"
              checked={product.isSelected}
              onChange={(e) => {
                e.preventDefault();
                onSelected(e.target.checked);
              }}
            />
          </div>
          <img src={img} alt="" className="rounded-xl mx-3 w-20 object-cover aspect-square" />

          <div className="flex flex-col space-y-1">
            <div className="font-monserrat font-normal text-xs text-dark">{product.type}</div>
            <div className="font-roboto font-normal text-xs text-[#706D65]">{product.nama}</div>
            <div className="font-monserrat font-normal text-xs text-dark">{product.brand}</div>
          </div>
        </div>
        <div className="flex space-x-4 justify-end">
          <div>
            <button onClick={() => onChange(product.quantity - 1)} disabled={product.quantity <= 1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-circle-minus"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="#576FAA"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M9 12l6 0" />
              </svg>
            </button>
          </div>
          <div className="font-roboto font-normal text-sm text-dark">{product.quantity || 0}</div>
          <div>
            <button onClick={() => onChange(product.quantity + 1)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke="#576FAA" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                <path d="M9 12h6" />
                <path d="M12 9v6" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Produk;
