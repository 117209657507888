import { useEffect, useState } from "react";
import Card from "./Card";
import { getBrands, getCategories, getDataProduct } from "../api";
import { useDebounce } from "@uidotdev/usehooks";

const Store = () => {
  const [dataAPI, setDataAPI] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [LastPage, setLastPage] = useState();
  const debouncedSearchTerm = useDebounce(inputValue, 500);
  const [listBrand, setListBrand] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const debouncedBrand = useDebounce(brand, 500);
  const debouncedCategory = useDebounce(category, 500);

  useEffect(() => {
    getCategories().then((res) => {
      setListCategory(res.data);
    });

    getBrands().then((res) => {
      setListBrand(res.data);
    });
  }, []);
  const [brandSelected, setBrandSelected] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const handleBrand = (event) => {
    setBrandSelected(event.target.value);
  };
  const handleCategory = (event) => {
    setCategorySelected(event.target.value);
  };
  const handleFilter = () => {
    setBrand(brandSelected);
    setCategory(categorySelected);
    toggleActive();
  };
  const filterReset = () => {
    setBrand("");
    setCategory("");
    toggleActive();
  };

  useEffect(() => {
    setIsLoading(true);
    getDataProduct({
      page: currentPage,
      limit: 8,
      type: "accessories",
      brand: debouncedBrand,
      category: debouncedCategory,
      search: debouncedSearchTerm,
    })
      .then((res) => {
        console.log(res.data);
        setDataAPI(res.data);
        setCurrentPage(res.current_page);
        setLastPage(res.last_page);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [debouncedSearchTerm, currentPage, debouncedBrand, debouncedCategory]);

  const handleSearch = (event) => {
    setInputValue(event.target.value);
  };

  const [active, setActive] = useState(false);
  const toggleActive = () => {
    setActive(!active);
  };

  const nextPage = () => {
    if (currentPage < LastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="animate-pulse bg-light md:px-20 px-10 py-8 ">
          <div className="lg:flex justify-between items-center">
            <h4 className="max-sm:text-2xl">Aksesoris yang tersedia</h4>
            <div className="max-lg:my-4 sm:flex items-center">
              <div className="border-2 border-[#D9D9D9] flex gap-3 rounded-md px-3 py-1 max-sm:w-full md:w-64 xl:w-96 font-[#C4C4C4]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-search my-1"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#C4C4C4"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                  <path d="M21 21l-6 -6" />
                </svg>
                <input type="text" className="w-full font-roboto font-normal text-[15px] focus:outline-none" placeholder="Cari Aksesoris di Arservices" />
              </div>
              <button className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium max-sm:w-full px-12 py-1 h-10 max-sm:my-2 sm:ml-5 hover:underline underline-offset-1" onClick={toggleActive}>
                Filter
              </button>
            </div>
          </div>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 py-8 gap-6">
            <div className="bg-white border-2 text-left shadow-md rounded-xl overflow-hidden">
              <div className="aspect-square bg-gray-400 rounded w-full h-2/3"></div>
              <div className="px-6 py-6 space-y-3">
                <div className="mb-4 bg-gray-400 rounded w-5/6 h-8"></div>
                <div className="bg-gray-400 rounded w-1/2 h-5"></div>
                <div className="bg-gray-400 rounded w-1/3 h-3"></div>
              </div>
            </div>
            <div className="bg-white border-2 text-left shadow-md rounded-xl overflow-hidden">
              <div className="aspect-square bg-gray-400 rounded w-full h-2/3"></div>
              <div className="px-6 py-6 space-y-3">
                <div className="mb-4 bg-gray-400 rounded w-5/6 h-8"></div>
                <div className="bg-gray-400 rounded w-1/2 h-5"></div>
                <div className="bg-gray-400 rounded w-1/3 h-3"></div>
              </div>
            </div>
            <div className="bg-white border-2 text-left shadow-md rounded-xl overflow-hidden">
              <div className="aspect-square bg-gray-400 rounded w-full h-2/3"></div>
              <div className="px-6 py-6 space-y-3">
                <div className="mb-4 bg-gray-400 rounded w-5/6 h-8"></div>
                <div className="bg-gray-400 rounded w-1/2 h-5"></div>
                <div className="bg-gray-400 rounded w-1/3 h-3"></div>
              </div>
            </div>
            <div className="bg-white border-2 text-left shadow-md rounded-xl overflow-hidden">
              <div className="aspect-square bg-gray-400 rounded w-full h-2/3"></div>
              <div className="px-6 py-6 space-y-3">
                <div className="mb-4 bg-gray-400 rounded w-5/6 h-8"></div>
                <div className="bg-gray-400 rounded w-1/2 h-5"></div>
                <div className="bg-gray-400 rounded w-1/3 h-3"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="bg-light md:px-20 px-10 py-8 ">
        <div className="lg:flex justify-between items-center">
          <h4 className="max-sm:text-2xl">Aksesoris yang tersedia</h4>
          <div className="max-lg:my-4 sm:flex items-center">
            <div className="border-2 border-[#D9D9D9] flex gap-3 bg-white rounded-md px-3 py-1 max-sm:w-full md:w-64 xl:w-96 font-#C4C4C4">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search my-1" width="32" height="32" viewBox="0 0 24 24" strokeWidth="2" stroke="#C4C4C4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                <path d="M21 21l-6 -6" />
              </svg>
              <input type="text" onChange={handleSearch} className="w-full font-roboto font-normal text-[15px] focus:outline-none" placeholder="Cari Aksesoris di Arservices" />
            </div>
            <button className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium max-sm:w-full px-12 py-1 h-10 max-sm:my-2 sm:ml-5 hover:underline underline-offset-1" onClick={toggleActive}>
              Filter
            </button>
          </div>
        </div>
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 py-8 gap-6">
          {dataAPI.map((product, i) => {
            return <Card dataapi={product} key={i} />;
          })}
        </div>
        <div className="flex justify-end">
          <button className="bg-dark w-fit rounded disabled:bg-gray-200" onClick={prevPage} disabled={currentPage === 1}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-chevron-left"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#ffffff"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 6l-6 6l6 6" />
            </svg>
          </button>
          <div className="w-fit flex border border-dark mx-3 rounded bg-white">
            <div className="font-roboto font-normal text-base text-dark px-3 self-center">{currentPage}</div>
          </div>
          <button className="bg-dark w-fit rounded disabled:bg-gray-200" onClick={nextPage} disabled={currentPage === LastPage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-chevron-right"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#ffffff"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 6l6 6l-6 6" />
            </svg>
          </button>
        </div>
      </div>
      <div className={`${active ? "" : "hidden"}`}>
        <div className="bg-black/50 fixed z-20 w-full h-full top-0 left-0 right-0" onClick={toggleActive} />
        <div className="fixed flex justify-center h-screen z-40 overflow-hidden top-0 left-0 w-screen">
          <div className="bg-white space-y-5 p-5 rounded self-center">
            <div className="flex justify-end">
              <button className=" text-[#0F75BC] hover:bg-gray-200 rounded" onClick={toggleActive}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#576FAA" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="space-y-5">
              <div className="font-roboto font-medium text-xl text-dark border-b border-dark">Brand</div>
              <form className="space-y-3 min-w-max">
                {listBrand.map((item, index) => {
                  return (
                    <div className="font-roboto font-normal text-sm text-dark" key={index}>
                      <input type="radio" id={`brand${index}`} name="brand" value={item.id} onChange={handleBrand} />
                      <label htmlFor={`brand${index}`}>{item.name}</label>
                    </div>
                  );
                })}
              </form>
              <div className="font-roboto font-medium text-xl text-dark border-b border-dark">Category</div>
              <form className="space-y-3 min-w-max">
                {listCategory.map((item, index) => {
                  return (
                    <div className="font-roboto font-normal text-sm text-dark" key={index}>
                      <input type="radio" id={`category${index}`} name="category" value={item.id} onChange={handleCategory} />
                      <label htmlFor={`category${index}`}>{item.name}</label>
                    </div>
                  );
                })}
              </form>
              <div className="flex justify-end space-x-5">
                <button className="bg-white text-dark border border-dark rounded-lg w-32 max-sm:w-full h-10 px-4 hover:underline underline-offset-1" onClick={filterReset}>
                  Reset
                </button>
                <button className="bg-dark text-white rounded-lg w-32 max-sm:w-full h-10 px-4 hover:underline underline-offset-1" onClick={handleFilter}>
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Store;
