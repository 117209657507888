import { React, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import dalfin_logo from "../assets/img/dalfin-logo.png";
import "../assets/styles/Navigation.css";
const Navigation = () => {
  const [active, setActive] = useState(false);
  const toggleActive = () => {
    setActive(!active);
  };
  return (
    <>
      <nav className="flex items-center justify-between bg-white sticky top-0 z-20">
        <img src={dalfin_logo} alt="" className="w-40 lg:mx-9 mx-6 my-6" />
        <div className={"lg:hidden mr-4"}>
          <div className={active ? "hidden" : ""} onClick={toggleActive}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#576FAA" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 6l16 0" />
              <path d="M4 12l16 0" />
              <path d="M4 18l16 0" />
            </svg>
          </div>
        </div>
        <div className="flex max-lg:hidden xl:gap-12 gap-8 xl:mr-7 mr-4 items-center">
          <ul className="lg:flex  xl:space-x-12 lg:space-x-8">
            <li className="py-1.5 text-[#0F75BC] hover:underline underline-offset-1">
              <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/"}>
                Home
              </NavLink>
            </li>
            <li className="relative group flex content-end py-1.5 text-[#0F75BC] hover:underline underline-offset-1">
              <div className="flex content-end">
                <span className="font-monserrat font-medium lg:text-[15px] text-sm self-end">Store</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-down ml-1 self-end"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  strokeWidth="2.5"
                  stroke="#0F75BC"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M6 9l6 6l6 -6" />
                </svg>
              </div>
              <ul className="absolute -left-5 top-8 hidden space-y-2 p-6 rounded shadow bg-white text-black group-hover:block">
                <li className="py-1.5 text-[#0F75BC] hover:underline underline-offset-1">
                  <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/store/accessories"}>
                    Accessories
                  </NavLink>
                </li>
                <li className="py-1.5 text-[#0F75BC] hover:underline underline-offset-1">
                  <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/store/sparepart"}>
                    Sparepart
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="py-1.5 text-[#0F75BC] hover:underline underline-offset-1">
              <a href="https://service.ajn-indonesia.co.id/" className="font-monserrat font-medium lg:text-[15px] text-sm">
                Komplain
              </a>
            </li>
            <li className="py-1.5 text-[#0F75BC] hover:underline underline-offset-1">
              <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/tracking"}>
                Tracking
              </NavLink>
            </li>
            <li className="py-1.5 text-[#0F75BC] hover:underline underline-offset-1">
              <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/garansi"}>
                Garansi
              </NavLink>
            </li>
            <li className="py-1.5 text-[#0F75BC] hover:underline underline-offset-1">
              <a href="https://service.ajn-indonesia.co.id/" className="font-monserrat font-medium lg:text-[15px] text-sm">
                Panduan
              </a>
            </li>
            <li className="py-1.5 text-[#0F75BC] hover:underline underline-offset-1">
              <a href="https://service.ajn-indonesia.co.id/" className="font-monserrat font-medium lg:text-[15px] text-sm">
                Kontak
              </a>
            </li>
          </ul>
          <div className="text-white hover:underline underline-offset-1">
            <a href="https://service.ajn-indonesia.co.id/" className="lg:px-9 px-6 py-2 bg-dark font-monserrat lg:text-[15px] text-sm font-medium rounded-md text-white">
              Login
            </a>
          </div>
        </div>
      </nav>
      <div className={`${active ? "" : "hidden"}`}>
        <div className="lg:hidden bg-black/50 fixed z-20 w-full h-full top-0 left-0 right-0" onClick={toggleActive} />
        <div className="lg:hidden fixed h-screen z-50 transition-all overflow-hidden top-0 right-0 bg-white w-64">
          <nav className="px-8 py-8">
            <div className="flex justify-end">
              <button className=" text-[#0F75BC] hover:bg-gray-200 rounded" onClick={toggleActive}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#576FAA" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div>
              <ul className="md:space-y-8 space-y-4">
                <li className="text-[#0F75BC] hover:underline underline-offset-1 ">
                  <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/"}>
                    Home
                  </NavLink>
                </li>
                <li className="text-[#0F75BC] hover:underline underline-offset-1 ">
                  <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/store/accessories"}>
                    Accessories
                  </NavLink>
                </li>
                <li className="text-[#0F75BC] hover:underline underline-offset-1 ">
                  <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/store/sparepart"}>
                    Sparepart
                  </NavLink>
                </li>

                <li className="text-[#0F75BC] hover:underline underline-offset-1 ">
                  <a href="https://service.ajn-indonesia.co.id/" className="font-monserrat font-medium lg:text-[15px] text-sm">
                    Komplain
                  </a>
                </li>
                <li className="text-[#0F75BC] hover:underline underline-offset-1 ">
                  <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/tracking"}>
                    Tracking
                  </NavLink>
                </li>
                <li className="text-[#0F75BC] hover:underline underline-offset-1 ">
                  <NavLink className="font-monserrat font-medium lg:text-[15px] text-sm" to={"/garansi"}>
                    Garansi
                  </NavLink>
                </li>
                <li className="text-[#0F75BC] hover:underline underline-offset-1 ">
                  <a href="https://service.ajn-indonesia.co.id/" className="font-monserrat font-medium lg:text-[15px] text-sm">
                    Panduan
                  </a>
                </li>
                <li className="text-[#0F75BC] hover:underline underline-offset-1 ">
                  <a href="https://service.ajn-indonesia.co.id/" className="font-monserrat font-medium lg:text-[15px] text-sm">
                    Kontak
                  </a>
                </li>
              </ul>
              <div className="text-white hover:underline underline-offset-1 mt-8">
                <a href="https://service.ajn-indonesia.co.id/" className="lg:px-9 px-6 py-2 bg-dark font-monserrat lg:text-[15px] text-sm font-medium rounded-md text-white">
                  Login
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navigation;
