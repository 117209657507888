import { React } from "react";
import img from "../assets/img/img.png";

const Order = ({ product }) => {
  return (
    <>
      <div className="relative bg-white border-b border-[#F0F0F0] overflow-hidden p-5">
        <div className="flex  justify-between my-5">
          <div className="flex items-center">
            <img src={img} alt="" className="rounded-xl mx-3 w-20 object-cover aspect-square" />
            <div className="flex flex-col space-y-1">
              <div className="font-monserrat font-normal text-xs text-dark">{product.jenis}</div>
              <div className="font-roboto font-normal text-xs text-[#706D65]">{product.nama}</div>
              <div className="font-monserrat font-normal text-xs text-dark">{product.brand}</div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="font-roboto font-normal text-sm text-dark">Jumlah({product.quantity})</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
