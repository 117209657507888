import React from "react";

const ProfileBiodata = () => {
  return (
    <div className="space-y-8 flex flex-col justify-between">
      <div className="space-y-8">
        <div className="grid lg:grid-cols-4 lg:space-x-12 max-lg:space-y-4">
          <div className="col-span-1">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Kode RS (KEMENKES)</div>
            <input type="text" placeholder="Ex :  1105034" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
          <div className="lg:col-span-3">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Nama Instansi</div>
            <input type="text" placeholder="Ex : RSUD Sultan Suriansyah Banjarmasin" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
        </div>
        <div className="grid lg:grid-cols-4 lg:space-x-12 max-lg:space-y-4">
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Kelas</div>
            <input type="text" placeholder="Pilih kelas" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Sector</div>
            <input type="text" placeholder="Pilih Sector" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Owner</div>
            <input type="text" placeholder="Pilih Owner" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Telepon</div>
            <input type="text" placeholder="Ex : 085752140606. . ." className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
        </div>
        <div className="grid lg:grid-cols-4 lg:space-x-12 max-lg:space-y-4">
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Provinsi</div>
            <input type="text" placeholder="Pilih provinsi" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Kabupaten/Kota</div>
            <input type="text" placeholder="Pilih kabupaten/kota" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
        </div>
        <div className="grid lg:grid-cols-2 space-x-12 border-b pb-9 border-[#D9D9D9] max-lg:space-y-4">
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Alamat Instansi</div>
            <input type="text" placeholder="Ex : RSUD Sultan Suriansyah Banjarmasin" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
        </div>
        <div className="grid lg:grid-cols-4 lg:space-x-12 max-lg:space-y-4">
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Email (opsional)</div>
            <input type="text" placeholder="Ex : 085752140606. . ." className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Password (opsional)</div>
            <input type="text" placeholder="Ex : 085752140606. . ." className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
        </div>
      </div>
      <div className="flex justify-end ">
        <button className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium max-sm:w-full px-12 py-1 h-10 max-sm:my-2 sm:ml-5 ">Simpan</button>
      </div>
    </div>
  );
};

export default ProfileBiodata;
