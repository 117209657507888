// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
// import empty_img from "../assets/img/empty.png";
import hero_img from "../assets/img/hero.jpeg";
import hero2_img from "../assets/img/hero2.png";
const Hero = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper w-full lg:h-[89vh] max-sm:h-auto"
      >
        <SwiperSlide>
          <div className="h-full py-8 pt-24">
            <img alt="" src={hero_img} className="absolute top-0 right-0 bottom-0 left-0 w-full h-full object-cover object-center" />
            <div className="bg-black/50 absolute w-full h-full top-0 left-0 right-0" />
            <div className="max-w-screen-2xl mx-auto z-10">
              <div className="relative pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
                <div className="mx-auto px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-24 lg:px-16 xl:mt-28">
                  <div className="text-center lg:text-left">
                    <h3 className="max-lg:text-3xl max-md:text-2xl text-5xl ">
                      <div>
                        <span className="text-[#0f75bc]">ARservice</span> gives you
                      </div>
                      a sense of security and comfort
                    </h3>
                    <div className="mt-5 sm:mt-8 flex justify-center lg:justify-start">
                      <a
                        href="https://service.ajn-indonesia.co.id/"
                        className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium py-1 max-sm:my-2 sm:ml-5 flex justify-center hover:underline underline-offset-1 shadow md:mt-6 mt-3 text-center md:w-[225px] w-40 md:h-[53px] h-8 items-center"
                      >
                        Baca Selengkapnya
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="h-full py-8 pt-24">
            <img alt="" src={hero_img} className="absolute top-0 right-0 bottom-0 left-0 w-full h-full object-cover object-center" />
            <div className="bg-black/50 absolute w-full h-full top-0 left-0 right-0" />
            <div className="max-w-screen-2xl mx-auto z-10">
              <div className="relative pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
                <div className="mx-auto px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-24 lg:px-16 xl:mt-28">
                  <div className="text-center lg:text-left">
                    <h3 className="max-lg:text-3xl max-md:text-2xl text-5xl ">
                      <div>
                        <span className="text-[#0f75bc]">ARservice</span> gives you
                      </div>
                      a sense of security and comfort
                    </h3>
                    <div className="mt-5 sm:mt-8 flex justify-center lg:justify-start">
                      <a
                        href="https://service.ajn-indonesia.co.id/"
                        className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium py-1 max-sm:my-2 sm:ml-5 flex justify-center hover:underline underline-offset-1 shadow md:mt-6 mt-3 text-center md:w-[225px] w-40 md:h-[53px] h-8 items-center"
                      >
                        Baca Selengkapnya
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="h-full py-8 pt-24">
            <img alt="" src={hero2_img} className="absolute top-0 right-0 bottom-0 left-0 w-full h-full object-cover object-center" />
            <div className="bg-black/50 absolute w-full h-full top-0 left-0 right-0" />
            <div className="max-w-screen-2xl mx-auto z-10">
              <div className="relative pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
                <div className="mx-auto px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-24 lg:px-16 xl:mt-28">
                  <div className="text-center lg:text-left">
                    <h3 className="max-lg:text-3xl max-md:text-2xl text-5xl ">
                      <div>
                        <span className="text-[#0f75bc]">ARservice</span> gives you
                      </div>
                      a sense of security and comfort
                    </h3>
                    <div className="mt-5 sm:mt-8 flex justify-center lg:justify-start">
                      <a
                        href="https://service.ajn-indonesia.co.id/"
                        className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium py-1 max-sm:my-2 sm:ml-5 flex justify-center hover:underline underline-offset-1 shadow md:mt-6 mt-3 text-center md:w-[225px] w-40 md:h-[53px] h-8 items-center"
                      >
                        Baca Selengkapnya
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Hero;
