import React from "react";

const ProfileUserAlat = () => {
  return (
    <div className="h-[500px] flex flex-col justify-between">
      <div className="">
        <div className="grid lg:grid-cols-3 lg:space-x-12 max-lg:space-y-4">
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Nama Pengguna Alat</div>
            <input type="text" placeholder="Ex : Sarimin" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Tipe Alat</div>
            <input type="text" placeholder="Alat yang bisa dipegang?" className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-3">Nomor HP</div>
            <input type="text" placeholder="Ex : 085752140606. . ." className="border border-[#D9D9D9] text-[11px] text-[#D9D9D9] rounded-md w-full h-10 px-2" />
          </div>
        </div>
        <div className="flex justify-end py-5 ">
          <div className="font-roboto font-normal text-sm text-dark">+ Tambah Pengguna Alat lain (opsional)</div>
        </div>
      </div>
      <div className="flex justify-end ">
        <button className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium max-sm:w-full px-12 py-1 h-10 max-sm:my-2 sm:ml-5 ">Simpan</button>
      </div>
    </div>
  );
};

export default ProfileUserAlat;
