import { useParams } from "react-router-dom";
import Detail from "./Detail";
import { useEffect, useState } from "react";
import { getDetailProduct } from "../api";

const StoreD = () => {
  const { slug } = useParams();

  const [dataAPI, setDataAPI] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetailProduct(slug)
      .then((res) => {
        setDataAPI(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [slug]);

  if (isLoading) {
    return (
      <>
        <div className="animate-pulse bg-light md:px-20 px-10 py-8 ">
          <div className="lg:flex justify-between items-center">
            <div className="h-8 w-80 bg-gray-400 rounded"></div>
            <div className="h-8 w-80 bg-gray-400 rounded"></div>
          </div>
          <div className=" py-8">
            <div className="bg-white shadow-md md:px-12 md:pt-16 pt-8">
              <div className="lg:grid grid-cols-4 pb-10">
                <div className="col-span-1 max-lg:px-12 max-sm:px-8 gap-6 overflow-hidden">
                  <div className="rounded-xl aspect-square object-cover bg-gray-400 h-96 w-full"></div>
                  <div className="animate-pulse grid grid-cols-4 gap-4 py-4">
                    <div className="rounded aspect-square object-cover bg-gray-400 h-24 w-full"></div>
                    <div className="rounded aspect-square object-cover bg-gray-400 h-24 w-full"></div>
                    <div className="rounded aspect-square object-cover bg-gray-400 h-24 w-full"></div>
                    <div className="rounded aspect-square object-cover bg-gray-400 h-24 w-full"></div>
                  </div>
                </div>
                <div className="col-span-3 px-12 max-sm:px-8 py-5 space-y-4">
                  <div className="bg-gray-400 rounded h-12 w-3/4"></div>
                  <div className="my-4 space-y-4">
                    <div className="bg-gray-400 rounded h-8 w-1/4"></div>
                    <div className="bg-gray-400 rounded h-8 w-1/4"></div>
                  </div>
                  <div className="bg-gray-400 rounded h-8 w-1/4"></div>
                  <div className="bg-gray-400 rounded h-8 w-1/4"></div>
                  <div className="bg-gray-400 rounded h-8 w-1/4"></div>
                  <div className="bg-gray-400 rounded h-8 w-1/4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="bg-light md:px-20 px-10 py-8 ">
        <div className="lg:flex justify-between items-center">
          <h4 className="max-sm:text-2xl">Detail {dataAPI.type === "accessories" ? "Accessories" : "Sparepart and Services"}</h4>
          {/* <div className="max-lg:my-4 sm:flex items-center">
            <div className="border-2 border-[#D9D9D9] flex gap-3 bg-white rounded-md px-3 py-1 max-sm:w-full md:w-64 xl:w-96 font-#C4C4C4">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search my-1" width="32" height="32" viewBox="0 0 24 24" strokeWidth="2" stroke="#C4C4C4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                <path d="M21 21l-6 -6" />
              </svg>
              <input type="text" className="w-full font-roboto font-normal text-[15px]" placeholder="Cari Aksesoris di Arservices" />
            </div>
            <button className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium max-sm:w-full px-12 py-1 h-10 max-sm:my-2 sm:ml-5 ">Filter</button>
          </div> */}
        </div>
        <div className="py-8">
          <Detail data={dataAPI} />
        </div>
      </div>
    </>
  );
};
export default StoreD;
