import React, { useState } from "react";
import { getDataWarranty } from "../api";

const Garansi = () => {
  const [active, setActive] = useState(false);
  const [dataAPI, setDataAPI] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [statusInformation, setStatusInformation] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  const postData = () => {
    getDataWarranty(inputValue)
      .then((res) => {
        console.log(res.data);
        setDataAPI(res.data);
        toggleActive();
        if (res.data.status === "success") {
          setStatusInformation(true);
        } else {
          setStatusInformation(false);
        }
      })
      .catch((err) => {
        setStatusInformation(false);
        toggleActive();
        console.log(err);
      });
  };
  const handleInput = (event) => {
    setInputValue(event.target.value);
  };
  return (
    <>
      <div className="flex justify-center py-[35vh] ">
        <div className="block space-y-20 max-sm:mx-8">
          <div className="font-roboto font-normal text-[32px] text-[#706D65] mx-6">Cek masa garansi produk Anda disini!</div>
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-2">Serial Number</div>
            <div className="sm:grid grid-cols-5 sm:space-x-3 max-sm:space-y-4">
              <div className="col-span-4">
                <input className="border border-[#D9D9D9] rounded-lg w-full h-10 px-4 focus:outline-none" type="text" placeholder="Ex : 989803100501. . ." onChange={handleInput} />
              </div>
              <div className="col-span-1">
                <button className="bg-dark text-white rounded-lg w-full h-10 px-4 hover:underline underline-offset-1" onClick={postData}>
                  Cek
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${active ? "" : "hidden"}`}>
        <div className="bg-black/50 fixed z-20 w-full h-full top-0 left-0 right-0" />
        <div className="fixed flex justify-center h-screen z-40 overflow-hidden top-0 left-0 w-screen">
          <div className="bg-white w-[600px] h-[440px] self-center rounded-md ">
            <div className="border border-[#F0F0F0] rounded-t-md px-6 py-5 flex justify-between">
              <div className="font-roboto font-medium text-base text-black">Informasi Garansi</div>
              <button className="text-black hover:bg-gray-200 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-x"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  onClick={toggleActive}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className={`${statusInformation ? "" : "hidden"} flex flex-col space-y-4 p-20`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-circle-check self-center"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="#4EBE6E"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M9 12l2 2l4 -4" />
              </svg>
              <div className="font-roboto font-normal text-sm text-[#706D65] self-center">
                Masa garansi produk anda berakhir sampai tanggal {dataAPI.warranty_end} (sisa {dataAPI.remaining_days} hari lagi)
              </div>
            </div>
            <div className={`${statusInformation ? "hidden" : ""} flex flex-col space-y-4 p-20`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-ban self-center"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="#E84B4B"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M5.7 5.7l12.6 12.6" />
              </svg>
              <div className="font-roboto font-normal text-sm text-[#706D65] self-center">Produk tidak ditemukan, harap masukkan serial number yang benar!</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Garansi;
