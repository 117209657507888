import { Link } from "react-router-dom";
import experience_img from "../assets/img/experience-img.png";
import kan_img from "../assets/img/kan-logo.png";
import tuv_img from "../assets/img/tuv-nord-logo.png";
import complaint_img from "../assets/img/complaint-img.png";
import accesories_img from "../assets/img/accesories-img.png";
import tracking_img from "../assets/img/tracking-img.png";
import Hero from "./Hero";

const Home = () => {
  return (
    <>
      <Hero />
      <div className="bg-light relative grid md:max-xl:pb-4">
        <div className="grid xl:grid-cols-2">
          <div className="cols-span-1 grid grid-cols-3">
            <div className="xl:ml-16 my-12 justify-self-center">
              <p className="font-roboto font-medium md:text-4xl sm:text-2xl text-xl">10.000+</p>
              <p className="font-roboto font-normal md:text-sm text-xs text-[#0f75bc] pl-1">Installations</p>
            </div>
            <div className="xl:ml-16 my-12 justify-self-center">
              <p className="font-roboto font-medium md:text-4xl sm:text-2xl text-xl">1000+</p>
              <p className="font-roboto font-normal md:text-sm text-xs text-[#0f75bc] pl-0.5">Services</p>
            </div>
            <div className="xl:ml-16 my-12 justify-self-center">
              <p className="font-roboto font-medium md:text-4xl sm:text-2xl text-xl">3000+</p>
              <p className="font-roboto font-normal md:text-sm text-xs text-[#0f75bc]">Complaints Done</p>
            </div>
          </div>
        </div>
        <div className="xl:absolute xl:right-8 xl:bottom-10 xl:z-10 bg-dark grid grid-rows-3 gap-6 pl-6 md:pr-15 pr-6 md:py-12 py-6 md:rounded-2xl md:max-xl:w-2/3 max-md:w-full justify-self-center">
          <p className="font-roboto font-normal lg:max-xl:text-lg md:text-base text-sm text-white flex md:gap-4 gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-discount-check-filled"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#ffffff"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                strokeWidth="0"
                fill="currentColor"
              />
            </svg>
            ARservices adalah layanan purna jual PT ARKAN JAYA NUSANTARA
          </p>
          <p className="font-roboto font-normal lg:max-xl:text-lg md:text-base text-sm text-white flex md:gap-4 gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-discount-check-filled"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#ffffff"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                strokeWidth="0"
                fill="currentColor"
              />
            </svg>
            ARservices menanggapi keluhan anda dengan lebih ramah dan efektif
          </p>
          <p className="font-roboto font-normal lg:max-xl:text-lg md:text-base text-sm text-white flex md:gap-4 gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-discount-check-filled"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#ffffff"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                strokeWidth="0"
                fill="currentColor"
              />
            </svg>
            ARservices menjamin keamanan dan kenyamanan alat yang anda beli
          </p>
        </div>
      </div>
      <div className="md:h-[500px] bg-light sm:grid grid-cols-2 max-sm:flex flex-col items-center justify-between lg:px-20 md:px-12 px-8 lg:gap-20 md:gap-12 gap-6 overflow-hidden">
        <img src={experience_img} alt="" className="xl:pt-20 max-sm:pb-6" />
        <div>
          <h3 className="lg:text-5xl md:text-4xl text-2xl sm:pb-8 pb-4">
            10+ years&ensp;
            <div className="max-sm:hidden">
              <br />
            </div>
            of experience!
          </h3>
          <p className="lg:text-xl md:text-md text-sm">
            We have a team of experienced professionals who have been in the industry for over 10+ years. Our engineers have a wealth of knowledge and skills that they have acquired over the years, making them experts in their field.
          </p>
          <div className="flex gap-8 w-20 my-6">
            <img src={tuv_img} alt="" />
            <img src={kan_img} alt="" />
          </div>
        </div>
      </div>
      <div className="xl:relative md:h-[500px] bg-white sm:grid grid-cols-2 max-sm:flex flex-col-reverse items-center justify-between lg:px-20 md:px-12 px-8 lg:gap-20 md:gap-12 gap-6 overflow-hidden">
        <div>
          <h3 className="lg:text-5xl md:text-4xl text-2xl sm:pb-8 pb-4">Complaint and Services</h3>
          <p className="lg:text-xl md:text-md text-sm">We respond to your complaints more friendly and effective. Complaint services are divided into two, Claim Warranty and Corrective Maintenance.</p>
          <div className="sm:flex">
            <a
              href="https://service.ajn-indonesia.co.id/"
              className="my-5 me-5 bg-dark rounded-md text-white font-monserrat text-[15px] font-medium w-48 py-1 h-10 max-sm:my-2 sm:ml-5 flex justify-center items-center hover:underline underline-offset-1 max-sm:w-full"
            >
              Komplain Sekarang
            </a>
            <Link
              to={"/garansi"}
              className="my-5 bg-white border-[1px] border-dark rounded-md text-dark font-monserrat text-[15px] font-medium w-48 h-10 py-1 max-sm:my-2 sm:ml-5 flex justify-center items-center hover:underline underline-offset-1 max-sm:w-full"
            >
              Cek Garansi
            </Link>
          </div>
        </div>
        <img src={complaint_img} alt="" className="xl:absolute -bottom-5 right-0 xl:w-3/5" />
      </div>
      <div className="md:h-[500px] bg-light sm:grid grid-cols-2 max-sm:flex flex-col items-center justify-between lg:px-20 md:px-12 px-8 max-sm:py-6 lg:gap-20 md:gap-12 gap-6 overflow-hidden">
        <img src={accesories_img} alt="" />
        <div>
          <h3 className="lg:text-5xl md:text-4xl text-2xl sm:pb-8 pb-4">
            Accesories and
            <br />
            Sparepart Shop
          </h3>
          <p className="lg:text-xl md:text-md text-sm">Get quality and guaranteed accessories only from us. Don't be afraid of making the wrong purchase, we accept consultations.</p>
          <Link
            to={"/store/accessories"}
            className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium w-48 py-1 h-10 max-sm:my-2 sm:ml-5 flex justify-center items-center hover:underline underline-offset-1 my-5 max-sm:w-full"
          >
            Belanja Sekarang
          </Link>
        </div>
      </div>
      <div className="md:h-[500px] bg-white sm:grid grid-cols-2 max-sm:flex flex-col-reverse items-center justify-between lg:px-20 md:px-12 px-8 lg:gap-20 md:gap-12 gap-6 overflow-hidden">
        <div>
          <h3 className="lg:text-5xl md:text-4xl text-2xl sm:pb-8 pb-4">Inventory and Tracking</h3>
          <p className="lg:text-xl md:text-md text-sm">View inventory that your agency has purchased and track its latest status.</p>
          <div className="sm:flex">
            <Link
              to={"/tracking"}
              className="bg-dark rounded-md text-white font-monserrat text-[15px] font-medium w-48 py-1 h-10 max-sm:my-2 sm:ml-5 flex justify-center items-center hover:underline underline-offset-1 my-5 me-5 max-sm:w-full"
            >
              Lihat Sekarang
            </Link>
            <Link
              to={"/tracking"}
              className="bg-white border-[1px] border-dark rounded-md text-dark font-monserrat text-[15px] font-medium w-48 h-10 py-1 max-sm:my-2 sm:ml-5 flex justify-center items-center hover:underline underline-offset-1 my-5 max-sm:w-full"
            >
              Tracking
            </Link>
          </div>
        </div>
        <img src={tracking_img} alt="" />
      </div>
      <div className="sm:flex bg-light justify-around h-52 lg:px-20 md:px-12 px-8 lg:gap-20 md:gap-4 items-center">
        <p className="text-dark text-monserrat lg:text-2xl md:text-xl text-lg font-medium max-sm:py-8">Akses panduan yang kami buat untuk para teknisi instansi anda.</p>
        <a
          href="https://service.ajn-indonesia.co.id/"
          className="bg-white border-[1px] border-dark rounded-md text-dark font-monserrat text-[15px] font-medium w-48 h-10 py-1 max-sm:my-2 sm:ml-5 flex justify-center items-center hover:underline underline-offset-1 max-sm:w-full"
        >
          Akses Sekarang
        </a>
      </div>
    </>
  );
};

export default Home;
