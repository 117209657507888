import { Link } from "react-router-dom";

export const LoginForm = () => {
  return (
    <>
      <div className="relative flex flex-col justify-center align-middle space-y-6">
        <div className="font-roboto font-bold text-[40px] text-[#1C1C55] text-center">Welcome !</div>
        <div className="font-roboto font-normal text-[22px] text-[#1C1C55] text-center">Log in your account</div>
        <input type="text" placeholder="Email" className="w-96 h-10 text-base rounded border border-[#D9D9D9] pl-2" />
        <input type="password" placeholder="Password" className="w-96 h-10 text-base rounded border border-[#D9D9D9] pl-2" />
        <div className="">
          <button className="bg-dark text-white rounded w-96 h-10 text-base">LOG IN</button>
          <div className="flex justify-end pt-2">
            <Link className="font-roboto font-normal text-[15px] text-dark">Lupa Password?</Link>
          </div>
        </div>
      </div>
    </>
  );
};
