import { Routes, Route } from "react-router-dom";
import Navigation from "./component/Navigation";
import Home from "./component/Home";
import Store from "./component/Store";
import StoreD from "./component/StoreD";
import Footer from "./component/Footer";
import wa_logo from "./assets/img/whatsapp-logo.png";
import Sparepart from "./component/Sparepart";
import { Keranjang } from "./component/Keranjang";
import Checkout from "./component/Checkout";
import Profile from "./component/Profile";
import Tracking from "./component/Tracking";
import Garansi from "./component/Garansi";
import { Login } from "./component/Login";

function App() {
  return (
    <div className="">
      <Navigation />
      <div className="">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store/accessories" element={<Store />} />
          <Route path="/store/sparepart" element={<Sparepart />} />
          <Route path="/store/detail/:slug" element={<StoreD />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/garansi" element={<Garansi />} />
          <Route path="/keranjang" element={<Keranjang />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
      <Footer />
      <a href="https://wa.me/6285752140605" target="_blank" rel="noopener noreferrer">
        <img src={wa_logo} alt="" className="z-30 fixed md:bottom-2 bottom-10 right-8 md:w-48 w-32" />
      </a>
    </div>
  );
}

export default App;
