import React from "react";
import { Link, useLocation } from "react-router-dom";
import Order from "./Order";

const Checkout = () => {
  const { state } = useLocation();

  return (
    <>
      <div className="bg-light md:px-20 px-10 py-8 ">
        <div className="font-roboto text-sm font-normal text-black">
          <Link className="text-[#8B887E]">Home /</Link> Keranjang
        </div>
        <div className="font-roboto font-medium text-black text-xl leading-10 py-5">Checkout</div>
        <div className="flex lg:flex-row flex-col-reverse lg:space-x-4">
          <div className="lg:basis-2/3">
            <div className="bg-white  border-b border-[#F0F0F0] rounded-lg p-5">
              <div className="font-roboto font-normal text-sm text-[#706D65] py-3  border-b border-[#D9D9D9]">Penanggung Jawab</div>
              <div className="grid md:grid-cols-3 gap-4">
                <div className=" ">
                  <div className="font-roboto font-normal text-sm text-[#706D65] py-3">Nama PIC</div>
                  <input type="text" className="border border-[#D9D9D9] rounded-md h-8 w-full" />
                </div>
                <div className="">
                  <div className="font-roboto font-normal text-sm text-[#706D65] py-3">Jabatan</div>
                  <input type="text" className="border border-[#D9D9D9] rounded-md h-8 w-full" />
                </div>
                <div className="">
                  <div className="font-roboto font-normal text-sm text-[#706D65] py-3">Nomor HP</div>
                  <input type="text" className="border border-[#D9D9D9] rounded-md h-8 w-full" />
                </div>
              </div>
              <div className="font-roboto font-normal text-sm text-[#706D65] py-3  border-b border-[#D9D9D9]">Alamat Pengiriman</div>
              <div className="">
                <div className="font-roboto font-normal text-sm text-[#706D65] pt-5 pb-1">RSUD</div>
                <div className="font-monserrat font-normal text-xs text-dark pb-1">628888888</div>
                <div className="font-roboto font-normal text-[10px] text-[#706D65] pb-1">JL.Kunsfsdf</div>
              </div>
            </div>
            <div className="">
              <div className="font-roboto font-medium text-black text-xl leading-10 py-5">Pesanan</div>
              {state?.data.map((product) => (
                <Order key={product.id} product={product} />
              ))}
            </div>
          </div>
          <div className="lg:basis-1/3 max-lg:mb-6">
            <div className="bg-white p-6 rounded-md">
              <div className="font-roboto font-normal text-sm text-[#706D65] py-3">Ringkasan belanja</div>
              {/* <div className="font-monserrat font-normal text-xs text-dark pb-5 mb-5 border-b border-[#D9D9D9]">Jumlah barang ({total})</div> */}
              {state?.data
                .filter((item) => item.isSelected)
                .map((item) => (
                  <div className="font-roboto font-normal text-xs text-[#706D65]" key={item.id}>
                    {item.nama} x {item.quantity}
                  </div>
                ))}
              <div className="bg-dark rounded-md text-center w-full h-9">
                <Link className="text-white font-monserrat text-xs font-medium leading-10 hover:underline underline-offset-1">PENAWARAN</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
