import React, { useState } from "react";
import { getDataTracking } from "../api";

const Tracking = () => {
  const [active, setActive] = useState(false);
  const [dataAPISummary, setDataAPISummary] = useState([]);
  const [dataAPIManifest, setDataAPIManifest] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [statusInformation, setStatusInformation] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };

  const postData = () => {
    getDataTracking(inputValue)
      .then((res) => {
        console.log(res);
        setDataAPISummary(res.data.summary);
        setDataAPIManifest(res.data.manifest);
        toggleActive();
        if (res.message === "success") {
          setStatusInformation(true);
        } else {
          setStatusInformation(false);
        }
      })
      .catch((err) => {
        setStatusInformation(false);
        toggleActive();
        console.log(err);
      });
  };

  const handleInput = (event) => {
    setInputValue(event.target.value);
  };
  const handleClearInput = () => {
    setInputValue("");
  };
  return (
    <>
      <div className="flex justify-center py-[35vh] ">
        <div className="block space-y-20 max-sm:mx-8">
          <div className="font-roboto font-normal text-[32px] text-[#706D65] mx-6">Tracking produk pesanan anda disini!</div>
          <div className="">
            <div className="font-roboto font-normal text-sm text-[#706D65] pb-2">Nomor Resi (didapat pada saat pembelian)</div>
            <div className="space-y-6">
              <input value={inputValue} onChange={handleInput} className="border border-[#D9D9D9] rounded-lg w-full h-10 px-4 focus:outline-none" type="text" placeholder="Ex : 989803100501. . ." />
              <div className="flex justify-end space-x-5">
                <button className="bg-white text-dark border border-dark rounded-lg w-32 max-sm:w-full h-10 px-4 hover:underline underline-offset-1" onClick={handleClearInput}>
                  Reset
                </button>
                <button className="bg-dark text-white rounded-lg w-32 max-sm:w-full h-10 px-4 hover:underline underline-offset-1" onClick={postData}>
                  Cek
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${active ? "" : "hidden"}`}>
        <div className="bg-black/50 fixed z-20 w-full h-full top-0 left-0 right-0" />
        <div className="fixed flex justify-center min-h-screen z-40 top-0 left-0 w-screen">
          <div className="bg-white self-center rounded-md max-[90vh]:">
            <div className="border border-[#F0F0F0] rounded-t-md px-6 py-5 flex justify-between">
              <div className="font-roboto font-medium text-base text-black">Informasi Tracking</div>
              <button className="text-black hover:bg-gray-200 rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-x"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  onClick={toggleActive}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className={`${statusInformation ? "" : "hidden"} flex flex-col space-y-8 p-14`}>
              <div className="grid md:grid-cols-5 sm:grid-cols-3 grid-cols-2">
                <div className="md:col-span-2 space-y-2 ">
                  <div className="font-roboto font-normal text-sm text-[#706D65] overflow-hidden">Nomor Resi </div>
                  <div className="font-roboto font-normal text-xs text-[#706D65] overflow-hidden">{dataAPISummary.waybill_number}</div>
                </div>
                <div className="md:col-span-2 space-y-2">
                  <div className="font-roboto font-normal text-sm text-[#706D65] overflow-hidden">Kurir</div>
                  <div className="font-roboto font-normal text-xs text-[#706D65] overflow-hidden">{dataAPISummary.courier_code}</div>
                </div>
                <div className=" space-y-2 max-sm:mt-4">
                  <div className="font-roboto font-normal text-sm text-[#706D65] overflow-hidden">Status</div>
                  <div className={`font-roboto font-normal text-xs text-white w-fit p-1.5 rounded overflow-hidden ${dataAPISummary.status === "DELIVERED" ? "bg-[#4EBE6E]" : "bg-red-700"}`}>{dataAPISummary.status}</div>
                </div>
              </div>
              <div className="grid grid-rows-3 overflow-hidden">
                {dataAPIManifest.map((item, index) => {
                  return (
                    <div className={`grid md:grid-cols-5 grid-cols-4 gap-2 p-3 ${index % 2 === 0 ? "bg-light" : "bg-[#F0F0F0]"}`} key={index}>
                      <div className="col-span-1 text-[#706D65] text-[10px] font-roboto font-normal">
                        {new Intl.DateTimeFormat("en-US", {
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }).format(new Date(item.manifest_date + "T" + item.manifest_time))}
                      </div>
                      <div className="md:col-span-4 col-span-3 text-[#706D65] text-[10px] font-roboto font-normal">
                        {item.manifest_description}: Drop point. {item.city_name}
                      </div>
                    </div>
                  );
                })}
                {/* <div className="grid md:grid-cols-5 grid-cols-4 gap-2 p-3 bg-light">
                  <div className="col-span-1 text-[#706D65] text-[10px] font-roboto font-normal">Oct 26. 4:03 pm</div>
                  <div className="md:col-span-4 col-span-3 text-[#706D65] text-[10px] font-roboto font-normal">Sedang Diantar: Drop Point, BANJARMASIN</div>
                </div>
                <div className="grid md:grid-cols-5 grid-cols-4 gap-2 p-3 bg-[#F0F0F0]">
                  <div className="col-span-1 text-[#706D65] text-[10px] font-roboto font-normal">Oct 26. 4:03 pm</div>
                  <div className="md:col-span-4 col-span-3 text-[#706D65] text-[10px] font-roboto font-normal">Telah Diambil: Drop Point, JAKARTA</div>
                </div> */}
              </div>
            </div>
            <div className={`${statusInformation ? "hidden" : ""} flex flex-col space-y-4 p-20`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-ban self-center"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="#E84B4B"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M5.7 5.7l12.6 12.6" />
              </svg>
              <div className="font-roboto font-normal text-sm text-[#706D65] self-center">Produk tidak ditemukan, harap masukkan nomor resi yang benar!</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tracking;
