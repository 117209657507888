import React from "react";
import img from "../assets/img/img.png";
import { Link } from "react-router-dom";

const Card = ({ dataapi }) => {
  return (
    <>
      <Link to={"/store/detail/" + dataapi?.id} className="bg-white border-2 text-left shadow-md rounded-xl overflow-hidden">
        <img src={dataapi?.thumbnail !== null ? dataapi?.thumbnail : img} alt="" className="object-cover aspect-square" />
        <div className="px-6 py-6">
          <p className="pb-4 text-lg">{dataapi?.name}</p>
          <h6 className="font-xl">{dataapi?.brand?.name}</h6>
          <h6 className="text-[#81B3DE] font-xs">{dataapi?.category?.name}</h6>
        </div>
      </Link>
    </>
  );
};

export default Card;
