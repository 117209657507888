import axios from "axios";

// const baseUrl = process.env.REACT_APP_BASEURL_API_ARSERVICES;
const baseUrl = "https://api.ajn-indonesia.co.id/api/ar-service";

export const getDataProduct = async (params) => {
  if (params === undefined) {
    const res = await axios.get(`${baseUrl}/product?${params}`);
    return res.data.data;
  } else {
    // console.log(params);
    const res = await axios.get(`${baseUrl}/product?page=${params.page}&limit=${params.limit}&type=${params.type}&search=${params.search}&brand=${params.brand}&category=${params.category}`);
    // console.log(res.data.data);
    return res.data.data;
  }
};

export const getDetailProduct = async (id = 1) => {
  const res = await axios.get(`${baseUrl}/product/${id}`);
  return res.data;
};
export const getBrands = async () => {
  const res = await axios.get(`${baseUrl}/brand`);
  return res.data.data;
};
export const getCategories = async (id = 1) => {
  const res = await axios.get(`${baseUrl}/category`);
  return res.data.data;
};

export const getDataTracking = async (waybill) => {
  const res = await axios.post(`${baseUrl}/tracking-check`, { waybill });
  return res.data;
};

export const getDataWarranty = async (serial_number) => {
  const res = await axios.post(`${baseUrl}/warranty`, { serial_number });
  return res;
};
